import { render, staticRenderFns } from "./hair-settings.vue?vue&type=template&id=54ccda24&scoped=true&"
import script from "./hair-settings.vue?vue&type=script&lang=js&"
export * from "./hair-settings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ccda24",
  null
  
)

export default component.exports