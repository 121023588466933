var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"subscription-form"}},[_c('b-form-group',{staticClass:"mb-3 w-100 h-100"},_vm._l((_vm.subscriptionBlocksOrdered),function(block){return (block.target !== 'group' && block.canSubscribe === true)?_c('b-form-radio',{key:block.key,staticClass:"subscribe-option pt-2 pl-5 pb-3 mb-0",class:{selected: _vm.subscription.key === block.key},attrs:{"value":block.key},nativeOn:{"click":function($event){return _vm.updateSubscriptionData(block.key)}},model:{value:(_vm.subscription.key),callback:function ($$v) {_vm.$set(_vm.subscription, "key", $$v)},expression:"subscription.key"}},[(_vm.userReceivingGift && _vm.userReceivingGift._id)?_c('div',{staticClass:"subscription-text ml-2 mb-1",domProps:{"innerHTML":_vm._s(_vm.$t('giftSubscriptionRateText', {price: block.price, months: block.months}))}}):_c('div',{staticClass:"subscription-text ml-2 mb-1",domProps:{"innerHTML":_vm._s(_vm.$t('subscriptionRateText', {price: block.price, months: block.months}))}}),_c('div',{staticClass:"ml-2",domProps:{"innerHTML":_vm._s(_vm.subscriptionBubbles(block.key))}})]):_vm._e()}),1),_c('div',{staticClass:"mx-4 mb-4 text-center"},[(_vm.note)?_vm._m(0):_vm._e()]),(_vm.userReceivingGift && _vm.userReceivingGift._id)?_c('payments-buttons',{attrs:{"disabled":!_vm.subscription.key,"stripe-fn":function () { return _vm.redirectToStripe({gift: _vm.gift, uuid: _vm.userReceivingGift._id, receiverName: _vm.receiverName}); },"paypal-fn":function () { return _vm.openPaypalGift({
      gift: _vm.gift, giftedTo: _vm.userReceivingGift._id, receiverName: _vm.receiverName,
    }); },"amazon-data":{type: 'single', gift: _vm.gift, giftedTo: _vm.userReceivingGift._id, receiverName: _vm.receiverName}}}):_c('payments-buttons',{attrs:{"disabled":!_vm.subscription.key,"stripe-fn":function () { return _vm.redirectToStripe({
      subscription: _vm.subscription.key,
      coupon: _vm.subscription.coupon,
    }); },"paypal-fn":function () { return _vm.openPaypal({url: _vm.paypalPurchaseLink, type: 'subscription'}); },"amazon-data":{
      type: 'subscription',
      subscription: _vm.subscription.key,
      coupon: _vm.subscription.coupon
    }}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('small',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(_vm.$t(_vm.note))+" ")])}]

export { render, staticRenderFns }